.chat-message-reactions-suggested {
    &-wrapper {
        position: absolute;
        right: -2.5rem;
        bottom: -7px;
        border-radius: 50%;
        padding: 0.5rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        margin-bottom: 0.2rem;
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--tx-color-chat-message);
        }
    }
    position: absolute;
    bottom: -0.4rem;
    right: 2.3rem;
    display: flex;
    background-color: #fff;
    padding: 0.2rem;
    border-radius: 10px;
    animation: fadeInRight cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #fff;
        right: -5px;
        top: 12px;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
