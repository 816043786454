@import "../../../src/Mixins";

.messenger-panel-contact {
    max-height: 4rem;
    overflow: hidden;

    &:hover {
        @include bp-up(m) {
            background-color: var(--bg-color-conversation-hover);
        }

        & .bg-color-info-circle-contact {
            @include bp-up(m) {
                background-color: var(--bg-color-info-circle);
            }
        }

        & .info-circle__name {
            @include bp-up(m) {
                color: var(--tx-color-preview-icon-hover);
            }
        }
    }

    &_text {
        max-width: calc(100% - 3.25rem);
    }

    &_status {
        bottom: -0.25rem;
        right: -0.5rem;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
    }
}
