@import "../../Mixins";

.quoted-message {
    border-radius: 12px;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.1) !important;

    @include bp-down(s) {
        padding: 0.25rem;
    }

    //to implement the bar like whatsapp in quoted message

    // &::after {
    //     position: absolute;
    //     top: 1.5rem;
    //     right: 5rem;
    //     content: '';
    //     height: 5rem;
    //     width: .5rem;
    //     background-color: rgba(0, 0, 0, 0.2);
    //     border-radius: 4px;
    // }

    &-container {
        margin-left: 10px;
        padding: 10px 0;
        background-color: var(--bg-color-chat-messages);
        border-radius: 12px;

        & img {
            width: 20rem;
        }
    
        & .link-icon-wrapper {
            display: none !important;
        }
    }
}
