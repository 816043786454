.chat-header {
    height: 3rem;
    &-wrapper {
    }
    &-info {
        max-width: calc(100% - 3.25rem);

        &__description {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
