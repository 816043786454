@import "../../../src/Mixins";

.messenger-panel {
    &-wrapper {
        transition: all 1s;
        max-width: 20rem;

        @include bp-down(s) {
            position: absolute;
            overflow: hidden;
            max-width: 100%;
            width: 100%;
            height: 100%;
        }
    }

    &-icon {
        top: 3rem;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        z-index: 1;
        color: var(--tx-color-icon);
        border: 2px solid #fff;

        @include bp-up(m) {
            display: none;
        }
    }
}
