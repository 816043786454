@import "../../../Mixins";

.chat-input {
    &-wrapper {
        border-radius: 1rem;
    }

    &__text {
        height: 40px;
        padding: 0.5rem;
        background: none;
        outline: none;
        resize: none;
    }
}

.chat-input-disabled {
    &-wrapper {
        border-radius: 1rem;
        background-color: #E0E0E0;
        border: 2px solid transparent;
    }

    &__text {
        height: 40px;
        padding: 0.5rem;
        text-align: center;
        font-weight: bold;
        color: var(--bs-body-color);
        background: none;
        outline: none;
        resize: none;
    }
}

.users-list-container {
    position: absolute;
    bottom: 4rem;
    z-index: 1;
    background: #fff;
    overflow-y: scroll;
    max-height: 30rem;
    max-width: 20rem !important;
}
.emoji-picker-react-wrapper {
    position: absolute !important;
    right: 0;
}
.emoji-picker-react {
    position: absolute !important;
    bottom: 0.5rem;
    right: 0;
    border: 2px solid var(--bg-color-chat-header) !important;
}
