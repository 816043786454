@mixin bp-up($size) {
    @if $size == xxs {
        @media (max-width: 399px) {
            @content;
        }
    } @else if $size == xs {
        @media (min-width: 400px) {
            @content;
        }
    } @else if $size == s {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $size == m {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $size == l {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $size == xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $size == xxl {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@mixin bp-down($size) {
    @if $size == xxxs {
        @media (max-width: 320px) {
            @content;
        }
    } @else if $size == xxs {
        @media (max-width: 399px) {
            @content;
        }
    } @else if $size == xs {
        @media (max-width: 575.98px) {
            @content;
        }
    } @else if $size == s {
        @media (max-width: 767.98px) {
            @content;
        }
    } @else if $size == m {
        @media (max-width: 991.98px) {
            @content;
        }
    } @else if $size == l {
        @media (max-width: 1199.98px) {
            @content;
        }
    } @else if $size == xl {
        @media (max-width: 1799.98px) {
            @content;
        }
    }
}

@mixin font-size($size) {
    font-size: ($size/10) + rem !important;

    @include bp-down(m) {
        font-size: ($size/11.5) + rem !important;
    }

    @include bp-down(s) {
        font-size: ($size / 13.5) + rem !important;
    }

    @include bp-down(xxs) {
        font-size: ($size/12) + rem !important;
    }
}

@for $i from 7 through 30 {
    .font-size-#{$i} {
        @include font-size($i);
    }
}
