.rounded-button-own-message {
    background-color: var(--bg-color-info-circle-own-message) !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 1px 12px !important;

    &:focus{
        box-shadow: none !important;
    }
}

.rounded-button-message {
    background-color: var(--bg-color-info-circle-message) !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 1px 12px !important;

    &:focus{
        box-shadow: none !important;
    }
}