//@import "~bootstrap/scss/bootstrap.scss";
@import "./theme/Main.scss";
@import "./Mixins";

html,
body,
#root {
    height: 100%;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.font-weight-bold {
    font-weight: bold;
}

input {
    outline: none;
    &:disabled {
        background-color: rgba(55, 55, 55, 0.08);
    }
}

p {
    @include bp-down(s) {
        margin-bottom: 0.5rem !important;
    }
}

.user-has-not-access {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.fa-rotate-45 {
    transform: rotate(45deg);
}

// Custom scroll bar

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-color);
}
