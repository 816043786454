@import "../../../src/Mixins";

.chat-messages {
    &-wrapper {
        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;

        @include bp-down(s) {
            padding: 0.25rem;
        }
    }
    &-loading {
        height: 1rem;
    }

    &-load-previous {
        margin: 0 auto;
        border-radius: 20px;
        padding: 5px;
        width: 50%;
        // background-color: var(--bg-color-chat-message);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        color: var(--tx-color-chat-message);
        font-weight: bold;
        text-align: center;
        cursor: pointer;
    }

    &-scroll-to-last {
        position: absolute;
        right: 3rem;
        color: #fff;
        background-color: var(--bg-color-panel-icon);
        // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        z-index: 3;
        border: 2px solid #fff;

        @include bp-down(s) {
            width: 2rem;
            height: 2rem;
            right: 1.5rem;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // color: grey;
        }
    }
}
