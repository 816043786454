.chat-message-reaction {
    &-wrapper {
        background-color: #fff;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
    }

    display: flex;
    align-items: center;
    padding: 1px;

    &-users {
        position: absolute;
        bottom: 2rem;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        width: 15rem;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

        &.own {
            right: 0.25rem;

            &::after {
                left: unset;
                right: 1px;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #fff;
            left: 1px;
            bottom: -2px;
        }
    }

    &__image {
        width: 1.3rem;
        height: 1.3rem;
    }
    &__count {
        font-size: small;
        padding-right: 4px;
        font-weight: bold;
        color:  var(--tx-color-chat-message);
    }
}
