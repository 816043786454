$switch-height: 1.5rem;
$switch-width: $switch-height * 2;
$slider-offset: .2rem;


.toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
}

.switch {

    position: relative;
    display: inline-block;
    width: $switch-width;
    height: $switch-height;

    &__checkbox {
        opacity: 0;
        height: 0;
        width: 0;
    }

    &__slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border-radius: $switch-height/2;
        transition: .4s;
    }

    &__slider:before {
        position: absolute;
        content: "";
        height: $switch-height - $slider-offset*2;
        width: $switch-height - $slider-offset*2;
        left: $slider-offset;
        bottom: $slider-offset;
        border-radius: 50%;
        background-color: white;
        transition: .4s;
    }
    
    &__checkbox:checked + .switch__slider {
        background-color: #c7aabe;
    }
    
    &__checkbox:focus + .switch__slider {
        box-shadow: 0 0 1px #c7aabe;
    }
    
    &__checkbox:checked + .switch__slider:before {
        transform: translateX($switch-width/2);
    }
}


