// Background color
.bg-color {
    &-header {
        background-color: var(--bg-color-header);
        // background-color: $bg-color-header;
    }
    &-panel {
        background-color: var(--bg-color-panel);
        &-icon {
            background-color: var(--bg-color-panel-icon);
        }
        &-conversation {
            &-selected {
                background-color: var(--bg-color-conversation-hover);

                & .tx-color-preview-icon,
                & .info-circle__name {
                    color: var(--tx-color-preview-icon-hover);
                }
            }
        }
        &-contact {
            &-online {
                background-color: var(--bg-color-contact-online);
            }
            &-offline {
                background-color: var(--bg-color-contact-offline);
            }
        }
    }
    &-chat {
        &-icon {
            background-color: var(--bg-color-chat-icon);
        }
        &-header {
            background-color: var(--bg-color-chat-header);
        }
        &-chat-messages {
            background-color: var(--bg-color-chat-messages);
        }
        &-input-box {
            background-color: var(--bg-color-chat-input-box);
        }
        &-quoted-message {
            background-color: var(--bg-color-chat-quoted-message);
        }
        &-own-message {
            background-color: var(--bg-color-chat-own-message);
        }
        &-message {
            background-color: var(--bg-color-chat-message);
        }
        &-attachments {
            background-color: var(--bg-color-chat-attachments);
        }
    }
    &-info-circle {
        &-header {
            background-color: var(--bg-color-info-circle);
        } 
        &-conversation {
            background-color: var(--bg-color-info-circle-conv);
            &-selected {
                background-color: var(--bg-color-info-circle-conv-sel);
            }
        }
        &-message {
            background-color: var(--bg-color-info-circle-message);
        }
        &-own-message {
            background-color: var(--bg-color-info-circle-own-message);
        }
    }
    &-transparent {
        background-color: transparent;
    }
}

// Border color
.border-color {
    &-search {
        border-color: var(--border-color-search) !important;
    }
}

// Text color
.tx-color {
    &-last-message {
        color: var(--tx-color-last-message);
    }
    &-preview-icon {
        color: var(--tx-color-preview-icon);
    }
    &-chat {
        &-message {
            color: var(--tx-color-chat-message);
        }
        &-quoted-message {
            color: var(--tx-color-chat-quoted-message);
        }
        &-attachments-close {
            color: var(--tx-color-chat-attachments-close);
        }
    }
}

// Buttons color
.message {

}

