@import "../../../src/Mixins";

.chat-message {
    display: flex;
    flex-direction: row;

    &.own {
        flex-direction: row-reverse;
    }

    &-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;

        &.own {
            margin-left: auto;
        }
    }

    &-content {
        background-color: #b4e6f7;
        padding: 0.4rem 0.7rem;
        width: 50%;
        border-radius: 15px;
        @include bp-down(s) {
            width: 70%;
        }
    }

    &-body {
        &__username {
            margin-bottom: 0.2rem;
            text-transform: capitalize;
        }
        &__content {
            word-break: break-word;
            width: 100%;
        }
    }

    &-separator {
        margin: 1rem 0;
        border-top: 2px solid #204f81;
        opacity: 1;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;
    }

    &-delete {
        position: absolute;
        background-color: #0000008c;
        top: 0;
        left: 0;
        border-radius: 15px;
        z-index: 2;

        &-trash {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &-wrapper {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.8);
                width: 3.5rem;
                height: 3.5rem;
                padding: 15px;
                border-radius: 50%;
                border: 1px solid #fff !important;
            }
        }
        &-redo {
            position: absolute;
            right: 10px;
            top: 5px;
            color: #fff !important;
            border: 1px solid #fff !important;
            background-color: rgba(0, 0, 0, 0.8) !important;
            padding: 0 20px !important;
            border-radius: 15px !important;

            @include bp-down(s) {
                &__label {
                    display: none;
                }
            }
        }
    }
}
