@import "../../../src/Mixins";

.messenger-panel-conversation {
    max-height: 4rem;
    overflow: hidden;

    &:hover {
        @include bp-up(m) {
            background-color: var(--bg-color-conversation-hover);
        }
        & .bg-color-info-circle-conversation {
            @include bp-up(m) {
                background-color: var(--bg-color-info-circle-conv-sel);
            }
        }

        & .tx-color-preview-icon {
            @include bp-up(m) {
                color: var(--tx-color-preview-icon-hover);
            }
        }

        & .info-circle__name {
            @include bp-up(m) {
                color: var(--tx-color-preview-icon-hover);
            }
        }
    }

    &_text {
        max-width: calc(100% - 3.25rem);
    }

    &_title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &_last-message {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-message_number {
        background-color: red;
        color: white;
        width: 1.2875rem;
        height: 1.2875rem;
    }
    &-message_pin {
        width: 1.125em;
    }
}
