.chat-message-reactions {
    &-wrapper {
        position: absolute;
        left: 3rem;
        bottom: 1px;
        &.own {
            left: unset;
            right: 3rem;
        }
    }
    bottom: 1.3rem;
    display: flex;
    padding: 0.2rem;
}
