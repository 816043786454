.chat-message-reaction-suggested {
    &-wrapper {
        cursor: pointer;
        margin: 0 0.5rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__image {
        width: 1.5rem;
        height: 1.5rem;
    }
    &__circle {
        height: 5px;
        width: 5px;
        border-radius: 50%;
    }
}
