:root {
    // Background color:
    --bg-color-header: #e0e0e0;
    --bg-color-info-circle: #c7aabe;
    --bg-color-info-circle-conv: #c7aabe;
    --bg-color-info-circle-conv-sel: #fff;
    --bg-color-info-circle-message: #c7aabe;
    --bg-color-info-circle-own-message: #8da3c3;
    --bg-color-panel-icon: #5a5a5a;
    --bg-color-panel: #f9f9f9;
    --bg-color-conversation-hover: #c7aabe;
    --bg-color-contact-online: #28a745;
    --bg-color-contact-offline: #5a5a5a;
    --bg-color-chat-icon: #5a5a5a;
    --bg-color-chat-header: #e0e0e0;
    --bg-color-chat-messages: #f9f9f9;
    --bg-color-chat-input-box: #fff;
    --bg-color-chat-quoted-message: #fff;
    --bg-color-chat-own-message: #d0d8e8;
    --bg-color-chat-message: #eee5ec;
    --bg-color-chat-attachments: #fff;
    // Border color:
    --border-color-search: #c7aabe;
    // Text color:
    --tx-color-icon: #fff;
    --tx-color-last-message: #59595b;
    --tx-color-name: #fff;
    --tx-color-preview-icon: #fff;
    --tx-color-preview-icon-hover: #c7aabe;
    --tx-color-chat-message: #59595b;
    --tx-color-chat-quoted-message: #59595b;
    --tx-color-chat-attachments-close: #59595b;
    // Others:
    --scrollbar-thumb-color: #c7aabe;
    --scrollbar-track-color: #eee5ec;
}

/*
// WiO colors:
$WiO-darkpink: #c7aabe;
$WiO-pink: #e3d4df;
$WiO-lightpink: #eee5ec;
$WiO-textpink: #d5bace;
$WiO-grey: #59595b;
$WiO-blue: #027bba;
$WiO-purple: #293174;
$WiO-chat-bg: #f7f1f5;
$WiO-chat-own-portrait: #8da3c3;
$WiO-chat-message: #eadce7;
$WiO-chat-own-message: #d0d8e8;
$WiO-chat-quote: #e2cfdd;
$WiO-chat-own-quote: #a2b4ce;
*/

// Bootstrap:
$light: #c7aabe;
$dark: #59595b;
$gray-900: #59595b;
$gray-300: #c7aabe;

@import "~bootstrap/scss/bootstrap.scss";

// Custom style:
.search-box {
    border-radius: 1rem;
    overflow: hidden;
}
.messenger-panel-wrapper {
    margin-right: 0.5rem;

    @media (max-width: 767.98px) {
        margin-right: 0;
    }
}
.messenger-panel-header {
    & div {
        position: relative;
        border-radius: 1rem;
        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 1rem;
            height: 1rem;
            border: 0.5rem solid transparent;
            background-color: var(--bg-color-panel);
            z-index: -1;
        }
        &:before {
            left: -1rem;
        }
        &:after {
            right: -1rem;
        }
        &.selected {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    & div:first-child {
        border-bottom-left-radius: 0;
        &:before {
            //display: none;
            left: 0;
            bottom: -1rem;
            background-color: var(--bg-color-header);
        }
        &.selected {
            &:before {
                background-color: var(--bg-color-panel);
            }
        }
    }
    & div:last-child {
        border-bottom-right-radius: 0;
        &:after {
            //display: none;
            right: 0;
            bottom: -1rem;
            background-color: var(--bg-color-header);
        }
        &.selected {
            &:after {
                background-color: var(--bg-color-panel);
            }
        }
    }
}
.messenger-panel-contact-list-wrapper,
.messenger-panel-conversation-list-wrapper {
    position: relative;
    border-radius: 1rem;
    & > div:first-child {
        margin-top: 0.5rem;
    }
}
.chat-container-wrapper {
    margin-left: 0.5rem;
    @media (max-width: 767.98px) {
        margin-left: 0;
    }
}
.chat-header-wrapper {
    border-radius: 1rem;
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
        background-color: var(--bg-color-chat-messages);
        z-index: -1;
    }
}
.chat-messages-wrapper {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.chat-message-separator {
    display: none;
}

.modal-button {
    color: #fff !important;
    background-color: #d5bace !important;
    border: 1px solid #d5bace !important;
    border-radius: 1rem !important;
    &:hover {
        border: 1px solid #d5bace !important;
        color: #fff !important;
        background-color: #d5bace !important;
    }
    &:focus {
        box-shadow: none !important;
    }
}
