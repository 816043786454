@import "../../../../src/Mixins";

.modal-button {
    border-radius: 15px !important;
    color: #fff !important;
    background-color: #59595b !important;
    font-weight: bold !important;
    &:hover {
        border: 1px solid #59595b !important;
        color: #59595b !important;
        background-color: #fff !important;
    }
    &:focus {
        box-shadow: none !important;
    }
}
.modal-input {
    border: 1px solid #59595b;
    border-radius: 15px;
    color: #59595b;
    padding: 0.25rem 0.5rem;
    width: 100%;
}
.error-message {
    color: red;
}
.link-icon-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    right: 1rem;
    top: 1rem;
    @include bp-down(s) {
        width: 1.5rem;
        height: 1.5rem;
        right: 0.5rem;
        top: 0.5rem;
    }
    & svg {
        font-size: 1.5rem;
        @include bp-down(s) {
            font-size: 1rem;
        }
    }
}
 