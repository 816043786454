@import "../../Mixins";

.chat-user-info {
    display: flex;
    position: absolute;
    border-radius: 1rem;
    box-shadow: 5px 5px 10px gray;
    background-color: white;
    top: 5.5rem;
    overflow: hidden;
    z-index: 3;
    width: 48%;

    @include bp-down(s) {
        width: 90%;
    }
    /*
    @include bp-down(m) {
        width: 60%;
    }
    @include bp-down(s) {
        width: 75%;
    }
    @include bp-down(xs) {
        width: 90%;
    }
    */

    &.hide {
        animation-duration: 0.5s;
        animation-name: fadeOut;
        animation-iteration-count: 1;
        opacity: 0;
        z-index: -1;

        @keyframes fadeOut {
            from {
                height: auto;
                opacity: 1;
                z-index: 1;
            }

            to {
                height: 0;
                opacity: 0;
                z-index: 1;
            }
        }
    }
    &.show {
        animation-duration: 0.5s;
        animation-name: fadeIn;
        animation-iteration-count: 1;
        opacity: 1;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    &__profile {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        display: flex;
        height: 3.5rem;
        width: 3.5rem;
        background-color: pink;
        border-radius: 50%;
        flex-shrink: 0;

        &-text {
            text-transform: uppercase;
            font-size: 1.5rem;
            color: white;
            margin: auto;
        }

        &-picture {
            cursor: pointer;
        }

        & img {
            text-transform: uppercase;
            font-size: 1.5rem;
            color: white;
            cursor: pointer;
            border-radius: 50%;
            height: 3rem;
            width: 3rem;
        }
    }

    &-body {
        width: 100%;
        margin: 0.5rem;

        &__name {
            display: flex;

            & > b {
                width: 100%;
                flex-shrink: 1;
            }
        }

        &__close {
            cursor: pointer;
            flex-shrink: 0;
            flex-grow: 0;
        }

        &__clinic {
            font-style: italic;
        }

        &__send {
            cursor: pointer;
        }

        &__send,
        &__send-disabled {
            margin-top: 1rem;
            color: pink;

            & > img {
                height: 2rem;
                width: 2.5rem;
                margin-left: 1rem;

                /*
                @include bp-down(xs) {
                    height: 1.25rem;
                    width: 1.5rem;
                    margin-left: 0.25rem;
                }
                */
            }
        }
    }
}
