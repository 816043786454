.info-circle {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;

        &-small {
            width: 2rem;
            height: 2rem;
        }
    }
    &__name {
        color: var(--tx-color-name);
    }
    &__picture {
        width: 2.5rem;
        height: 2.5rem;
        background-size: cover;
        background-position: 50%;
        //border-radius: 50%;
        //margin: auto;
    }
}
