.menu-options {
    &-wrapper {
        position: absolute;
        top: 90%;
        right: 5px;
        width: auto;
        min-width: 7rem;
        background-color: #fff;
        padding: 0.4rem;
        border-radius: 10px;
        animation: fadeInRight cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
        z-index: 1;

        &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
            left: 70%;
            top: -5px;
        }
    }

    &-item {
        & div {
            width: 20px;
        }
    }
}
